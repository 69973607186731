import { ref, computed, onMounted, watch } from "vue";

import { useStore } from "vuex";

export default function Setup() {
  const store = useStore();
  const clickPath = computed(() => {
    return store.state.app.clickPath;
  });

  const smallWindow = store.state.app.windowSize === ('xs' && 'sm');
  const xlargeWindow = store.state.app.windowSize === ('xl' && 'xxl');

  const sideNavSlim = smallWindow ? ref(false) : true;
  const slim1 = smallWindow || xlargeWindow ? false : ref(true);
  const slimShow1 = ref(false);
  const slimShow2 = ref(false);

  const hide = (bool) => {
    if (xlargeWindow) return
    slimShow1.value = false;
    slimShow2.value = false;
    if (bool) {
      smallWindow ? sideNavSlim.value = false : slim1.value = true;
    }
    else {
      smallWindow ? sideNavSlim.value = true : slim1.value = false;
    }
  }
  const hidden = computed(() => {
    return smallWindow ? !sideNavSlim.value : slim1.value
  });

  onMounted(() => {
    let decal = document.querySelector("header").scrollHeight;
    let sideNav = document.getElementById("sideNavSlim");
    sideNav.style.marginTop = decal + "px";
    // below = prevent animations
    sideNav.style.display = "none";
    hide(false);
    setTimeout(() => {
      sideNav.style.display = "";
      hide(true);
    });
  });

  watch(clickPath, () => {
    if (!clickPath.value.includes(document.getElementById("sideNavigation")))
      hide(true);
  });

  return {
    hide,
    hidden,
    sideNavSlim,
    slim1,
    slimShow1,
    slimShow2
  };
}
