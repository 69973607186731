<template>
  <div id="sideNavigation">
    <div
      v-if="$store.state.app.windowSize !== ('xl' && 'xxl')"
      class="position-absolute top-0 start-0 mt-5 pt-3 ps-1 text-center above"
    >
      <!-- Toogles -->
      <MDBBtn
        class="mt-2 ms-1 px-3 py-1"
        color="primary"
        aria-controls="#sidenavSlim"
        @click="hide(!hidden)"
      >
        <MDBIcon icon="bars" iconStyle="fas" size="2x" />
      </MDBBtn>
    </div>

    <!-- Sidenav-->
    <MDBSideNav
      v-model="sideNavSlim"
      :slim="slim1"
      :slimCollapsed="!slimShow1 && !slimShow2"
      :backdrop="false"
      id="sideNavSlim"
      :class="
        $store.state.app.windowSize !== ('xl' && 'xxl')
          ? ''
          : 'position-relative mt-0'
      "
    >
      <MDBSideNavMenu
        accordion
        scrollContainer
        :class="
          $store.state.app.windowSize !== ('xl' && 'xxl') ? 'pt-4' : 'pt-1'
        "
      >
        <MDBSideNavItem>
          <MDBSideNavLink>
            <MDBIcon iconStyle="far" icon="smile" class="fa-fw me-3" />
            <span class="sidenav-non-slim">Link 1</span>
          </MDBSideNavLink>
        </MDBSideNavItem>
        <MDBSideNavItem
          collapse
          icon="grin"
          title="Category 1"
          nonSlim
          :show="slimShow1"
          @click="slimShow1 = !slimShow1"
        >
          <MDBSideNavItem>
            <MDBSideNavLink>Link 2</MDBSideNavLink>
          </MDBSideNavItem>
          <MDBSideNavItem>
            <MDBSideNavLink>Link 3</MDBSideNavLink>
          </MDBSideNavItem>
        </MDBSideNavItem>
        <MDBSideNavItem
          collapse
          icon="grin-wink"
          title="Category 2"
          nonSlim
          :show="slimShow2"
          @click="slimShow2 = !slimShow2"
        >
          <MDBSideNavItem>
            <MDBSideNavLink>Link 4</MDBSideNavLink>
          </MDBSideNavItem>
          <MDBSideNavItem>
            <MDBSideNavLink>Link 5</MDBSideNavLink>
          </MDBSideNavItem>
        </MDBSideNavItem>
      </MDBSideNavMenu>
    </MDBSideNav>
  </div>
</template>

<script setup>
import {
  MDBSideNav,
  MDBSideNavMenu,
  MDBSideNavItem,
  MDBSideNavLink,
  MDBBtn,
  MDBIcon,
} from "mdb-vue-ui-kit";

import Setup from "./SideNavigationSetup";

const { hide, hidden, sideNavSlim, slim1, slimShow1, slimShow2 } = Setup();
</script>